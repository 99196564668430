:root {
  --lightblue: #b7d4e8;
  --blue: #3e86c3;
  --lightyellow: #fde974;
  --yellow: #fdd022;
  --lightgreen: #bbe6b3;
  --green: #699053;
  --lightred: #db95a2;
  --red: #b03141;
}

@font-face{
  font-family:"Campton W00 Book";
  src:url("Fonts/1422979/4f3bad58-5f06-4a10-b3a1-0243c8b6eb3c.eot?#iefix");
  src:url("Fonts/1422979/4f3bad58-5f06-4a10-b3a1-0243c8b6eb3c.eot?#iefix") format("eot"),url("Fonts/1422979/67b0b0ae-fe9f-4c2a-8673-6a697b81e659.woff2") format("woff2"),url("Fonts/1422979/f4a8087d-b3f5-4f7a-83ec-4717e0d529ad.woff") format("woff"),url("Fonts/1422979/5681ce99-0e73-493d-bfe7-127c55b314cb.ttf") format("truetype");
}
@font-face{
  font-family:"Campton W00 ExtraBold";
  src:url("Fonts/1422995/7b742053-f196-4708-a38b-be0b4020c26c.eot?#iefix");
  src:url("Fonts/1422995/7b742053-f196-4708-a38b-be0b4020c26c.eot?#iefix") format("eot"),url("Fonts/1422995/90dd9ab6-1709-4036-b4e1-0906e263f419.woff2") format("woff2"),url("Fonts/1422995/3c965a6b-54fa-4259-9907-2c3fdd955bc4.woff") format("woff"),url("Fonts/1422995/c737fc3d-a8ee-4200-bec5-7c5851254000.ttf") format("truetype");
}
@font-face{
  font-family:"Campton W00 Light DEMO";
  src:url("Fonts/5386156/64a3fa3c-e11f-45c5-b3d1-64924aa616f7.eot?#iefix");
  src:url("Fonts/5386156/64a3fa3c-e11f-45c5-b3d1-64924aa616f7.eot?#iefix") format("eot"),url("Fonts/5386156/fc786966-cf35-45dd-8810-1c880064fbe2.woff2") format("woff2"),url("Fonts/5386156/f4d31015-fea6-4279-b98b-84645b6b6b09.woff") format("woff"),url("Fonts/5386156/6c166b1e-a0dd-4120-ad27-39dd2e4de199.ttf") format("truetype");
}

* {
  box-sizing: border-box;
  font-family: 'Campton W00 Book';
}

.App {
  background-color: var(--lightblue);
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 2em;
}

h1, h2, h3 {
  font-family: 'Campton W00 ExtraBold';  
  margin: 0;
}

h4, h5, h6 {
  font-family: 'Campton W00 Light DEMO';
}

.App {
  text-align: center;
}

.three-col {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  overflow: hidden;
}

/* Header */

.global-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* min-width: 100vw; */
  background-color: var(--lightblue);
}

.container-logo {
  padding: 10px;
  max-width: 350px;
}

/* Footer */

.global-footer {
  width: 100%;
  background-color: var(--lightblue);
  /* background-image: linear-gradient(to left, var(--blue), var(--lightblue)); */
  display: flex;
}

.container-logo-sm {
  padding: 10px;
  max-width: 150px;
}


/* Sections */

.section-banner-outer {
  /* background-color: var(--green);   */
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-banner {
  flex-grow: 1;
  max-width: 600px;
  background-color: var(--blue);  
  text-align: center;
}

.section-banner > h1 {    
  margin: 0;
}


/* Accordion */

.accordion-heading {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 1.5em;
  width: 100%;
  text-align: center;
  background-color: grey;
  background-image: linear-gradient(to right, var(--blue), var(--lightblue));
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  /* margin-bottom: 5px;   */  
  max-width: 600px;
}

.accordion-child {  
  background-color: var(--lightblue);
  display: flex;
  max-width: 600px;
  justify-content: space-around;
  padding: 10px;
}

/* Main Content */

.main {
  background-color: var(--lightblue);
  max-width: 1500px;
}


.img {
  height: inherit;
  width: inherit;
  object-fit: contain;  
}

.contact-form {  
  display: flex;
  flex-direction: column;
  justify-content: center;  
  align-items: center;
  border-radius: 10px;
  background-color: grey;
  padding: 20px;
}

.form-label {
  width: 150px;
  /* background-color: red; */
  text-align: right;
}

.form-item {
  display: flex;
}

.form-input {
  resize: none;
  width: 300px;
}


@media (max-width:599px) {

  .phone-hide {
    visibility: hidden;
    height: 0;
  }

  .three-col {
    flex-direction: column;
  }

  .accordion-img {    
    width: 275px;
  }

  .menu-container {    
    display: flex;
    flex-direction: column;
    align-content: center;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 20px;
  }

  .services-img {    
    height: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .accordion-child {
    background-color: var(--lightblue);
    display: flex;
    flex-direction: column-reverse;    
    flex-wrap: wrap;
  }

  .accordion-text { 
    text-align: left;
  }

  .global-footer {
    flex-direction: column-reverse;   
    justify-content: center;
    align-content: center;    
  }

  .content-block {
    margin: 20px;
  }

  .container-footer-logo {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .contact-info {
    text-align: center;
  }

  .container-contact-info {
    display: flex;    
    justify-content: center;    
  }

  .services-container {
    width: 100%;
  }

  .projects-video {
    width: -webkit-calc(100% - 500px);
    width:    -moz-calc(100% - 500px);
    width:         calc(100% - 50px);
    margin-top: 15%;
  }

  .accordion-img-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
  }

  .about-text {    
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .services-text-outer {
    display: flex;
    justify-content: center;
  }
  
  .services-text {    
    text-align: left;
    margin: 20px;
  }

  .content-col {
    max-width: 600px;
    text-align: left;
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel {    
    margin-left: 20px;
    margin-right: 20px;
  }

}

@media (min-width:600px) {

  .services-text-outer {
    display: flex;
    justify-content: center;    
  }

  .services-text {
    max-width: 600px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .about-text {
    text-align: left;
    margin-bottom: 20px;
  }

  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .carousel {
    max-width: 600px;        
  }

  .accordion-img-container {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 0px;
  }

  .accordion-img {    
    width: 300px;
  }

  .projects-video {
    width: -webkit-calc(100% - 500px);
    width:    -moz-calc(100% - 500px);
    width:         calc(100% - 50px);
    margin-top: 25%;
  }

  .section-container {
    background-color: var(--lightblue);
  }

  .content-col {
    max-width: 600px;
    text-align: left;
  }

  .content-block {    
    min-height: 300px;
    max-width: 600px;
    text-align: left;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 0%);
    display: flex;  
    flex-direction: column;
    margin: 20px;
  }

  .menu-container-outer {
    display: flex;    
    justify-content: space-around;
    align-content: center;
    height: 100%;    
  }

  .menu-container {
    min-width: 600px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .services-container {    
    display: flex;
    justify-content: space-around;
    overflow: hidden;
  }

  .accordion-text {
  
    text-align: left;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 500px;
  }

  .services-img {    
    width: 300px;
    height: 300px;
    margin: 20px;
  }

  .container-footer-logo {
    display: flex;
    justify-content: center;    
    align-content: center;
    flex-direction: column;
  }

  .container-contact-info {
    padding: 10px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
  }
  
  .contact-info {
    text-align: right;
    /* background-color: red; */
    vertical-align: middle;  
  }

  .global-footer {    
    justify-content: space-between;
    align-content: center;
  }
}